import React from 'react';
import SVG from './base/SVG';
import Path from './base/Path';
import PropTypes from 'prop-types';

const ArrowWhite = ({ ...props }) => {
    return (
        <SVG viewBox="0 0 74.6 47.8" {...props}>
            <Path d="M74.6 37.7 37.7 0 0 36.9l10.1 10.3 27.4-26.8 26.8 27.4z" />
        </SVG>
    );
};

ArrowWhite.propTypes = {
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

ArrowWhite.defaultProps = {
    height: '16px',
    viewBox: '0 0 74.6 47.8',
    width: '17px',
};

export default ArrowWhite;
