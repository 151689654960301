import React from 'react';
import SVG from './base/SVG';

const Glitter = ({ className }) => (
    <SVG className={className} width="30px" space="preserve" enable-background="new 0 0 150 150" viewBox="0 0 150 150">
        <defs>
            <filter id="a" width="44.3" height="44.3" x="52.5" y="52.5" filterUnits="userSpaceOnUse">
                <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
            </filter>
        </defs>
        <mask id="c" width="44.3" height="44.3" x="52.5" y="52.5" maskUnits="userSpaceOnUse">
            <g filter="url(#a)">
                <radialGradient id="b" cx="204.6009" cy="67.1847" r="41.8411" gradientTransform="rotate(45 44.24819443 14.5660503) scale(.5292)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#fff" />
                    <stop offset=".07337778" stop-color="#d2d2d2" />
                    <stop offset=".1644" stop-color="#a1a1a1" />
                    <stop offset=".2605" stop-color="#767676" />
                    <stop offset=".3599" stop-color="#515151" />
                    <stop offset=".4635" stop-color="#343434" />
                    <stop offset=".5727" stop-color="#1d1d1d" />
                    <stop offset=".69" stop-color="#0d0d0d" />
                    <stop offset=".8211" stop-color="#030303" />
                    <stop offset=".9945" />
                </radialGradient>
                <path fill="url(#b)" d="M59 59c8.6-8.6 22.7-8.6 31.3 0 8.6 8.6 8.6 22.7 0 31.3-8.6 8.7-22.6 8.7-31.3 0-8.6-8.6-8.6-22.6 0-31.3z" />
            </g>
        </mask>
        <path fill="#fff" d="M59 59c8.6-8.6 22.7-8.6 31.3 0 8.6 8.6 8.6 22.7 0 31.3-8.6 8.7-22.6 8.7-31.3 0-8.6-8.6-8.6-22.6 0-31.3z" mask="url(#c)" />
        <defs>
            <filter id="d" width="105.7" height="105.7" x="21.8" y="21.8" filterUnits="userSpaceOnUse">
                <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
            </filter>
        </defs>
        <mask id="f" width="105.7" height="105.7" x="21.8" y="21.8" maskUnits="userSpaceOnUse">
            <g filter="url(#d)">
                <radialGradient id="e" cx="204.2525" cy="67.2658" r="92.794" gradientTransform="rotate(45 44.24819443 14.5660503) scale(.5292)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#fff" />
                    <stop offset=".02950251" stop-color="#efefef" />
                    <stop offset=".141" stop-color="#b8b8b8" />
                    <stop offset=".2562" stop-color="#878787" />
                    <stop offset=".3726" stop-color="#5d5d5d" />
                    <stop offset=".4906" stop-color="#3b3b3b" />
                    <stop offset=".6105" stop-color="#212121" />
                    <stop offset=".7333" stop-color="#0f0f0f" />
                    <stop offset=".8607" stop-color="#040404" />
                    <stop offset="1" />
                </radialGradient>
            </g>
        </mask>
        <g mask="url(#f)">
            <path d="M43 106.3c-1.3 1.3-2 2.1-2 2.1.6-.6 1.3-1.3 2-2.1C48.6 101 64.1 85.9 75 75c10.9-10.9 26-26.4 31.4-32 .7-.7 1.4-1.4 2-2.1 0 0-.8.8-2.1 2-5.6 5.4-21.1 20.5-32 31.4-10.8 11-25.9 26.5-31.3 32z" class="st7" />
            <defs>
                <filter id="g" width="67.5" height="67.5" x="40.9" y="40.9" filterUnits="userSpaceOnUse">
                    <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                </filter>
            </defs>
            <mask id="h" width="67.5" height="67.5" x="40.9" y="40.9" maskUnits="userSpaceOnUse">
                <g filter="url(#g)" />
            </mask>
            <path fill="#fff" d="M106.4 43.1c1.3-1.4 2-2.1 2-2.1-.7.7-1.4 1.3-2.1 2-5.7 5.2-21.4 20.1-32.3 31-10.9 10.9-25.7 26.7-31.1 32.3-.7.7-1.3 1.4-2 2.1 0 0 .8-.7 2.1-2 5.7-5.3 21.4-20.2 32.3-31.1s25.8-26.6 31.1-32.2z" mask="url(#h)" opacity=".3" />
            <defs>
                <filter id="i" width="44.2" height="44.2" x="52.6" y="52.6" filterUnits="userSpaceOnUse">
                    <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                </filter>
            </defs>
            <mask id="j" width="44.2" height="44.2" x="52.6" y="52.6" maskUnits="userSpaceOnUse">
                <g filter="url(#i)" />
            </mask>
            <path fill="#fff" d="M54 53.9c-.9-.8-1.4-1.3-1.4-1.3.4.5.9.9 1.4 1.3 3.5 3.7 13.2 13.9 20.4 21.1 7.1 7.1 17.3 16.9 21 20.5.5.4.9.9 1.4 1.3 0 0-.5-.5-1.3-1.4-3.5-3.7-13.3-13.9-20.4-21-7.2-7.2-17.4-17-21.1-20.5z" mask="url(#j)" />
            <defs>
                <filter id="k" width="105.7" height="105.7" x="21.8" y="21.8" filterUnits="userSpaceOnUse">
                    <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                </filter>
            </defs>
            <mask id="l" width="105.7" height="105.7" x="21.8" y="21.8" maskUnits="userSpaceOnUse">
                <g filter="url(#k)" />
            </mask>
            <path fill="#fff" d="M25.1 25c-2.1-2-3.3-3.2-3.3-3.2 1.1 1.1 2.1 2.2 3.2 3.3 8.4 8.8 31.9 33.1 49 50.2s41.5 40.6 50.2 49c1.1 1.1 2.2 2.1 3.3 3.2 0 0-1.2-1.2-3.2-3.3-8.4-8.8-31.9-33.2-49-50.2-17-17.1-41.4-40.6-50.2-49z" mask="url(#l)" opacity=".3" />
            <defs>
                <filter id="m" width="65.5" height="70" x="41.9" y="39.7" filterUnits="userSpaceOnUse">
                    <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                </filter>
            </defs>
            <mask id="n" width="65.5" height="70" x="41.9" y="39.7" maskUnits="userSpaceOnUse">
                <g filter="url(#m)" />
            </mask>
            <path fill="#fff" d="M105.5 41.9c1.2-1.4 1.9-2.2 1.9-2.2l-2.1 2.1C99.8 47.3 84.6 62.7 74 74c-10.6 11.3-25 27.6-30.1 33.5-.6.7-1.3 1.5-1.9 2.2l2.1-2.1c5.5-5.5 20.8-21 31.4-32.3 10.5-11.3 24.8-27.6 30-33.4z" mask="url(#n)" opacity=".3" />
            <defs>
                <filter id="o" width="41.1" height="47" x="54.1" y="51.2" filterUnits="userSpaceOnUse">
                    <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                </filter>
            </defs>
            <mask id="p" width="41.1" height="47" x="54.1" y="51.2" maskUnits="userSpaceOnUse">
                <g filter="url(#o)" />
            </mask>
            <path fill="#fff" d="M55.4 52.6c-.8-.9-1.3-1.4-1.3-1.4l1.2 1.5c3.2 4 12 15 18.6 22.6 6.7 7.6 16.4 17.8 20 21.5.4.5.9.9 1.3 1.4 0 0-.4-.6-1.2-1.5-3.2-4-12-15-18.6-22.6-6.6-7.7-16.4-17.9-20-21.5z" mask="url(#p)" opacity=".3" />
        </g>
        <defs>
            <filter id="q" width="34.6" height="34.6" x="57.4" y="57.4" filterUnits="userSpaceOnUse">
                <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
            </filter>
        </defs>
        <mask id="s" width="34.6" height="34.6" x="57.4" y="57.4" maskUnits="userSpaceOnUse">
            <g filter="url(#q)">
                <radialGradient id="r" cx="324.1914" cy="-218.0503" r="28.6931" gradientTransform="translate(-96.9843 190.0961) scale(.5292)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#fff" />
                    <stop offset=".04326093" stop-color="#d7d7d7" />
                    <stop offset=".09940881" stop-color="#aaa" />
                    <stop offset=".1613" stop-color="#828282" />
                    <stop offset=".2274" stop-color="#5e5e5e" />
                    <stop offset=".299" stop-color="#414141" />
                    <stop offset=".3779" stop-color="#292929" />
                    <stop offset=".4671" stop-color="#171717" />
                    <stop offset=".5727" stop-color="#0a0a0a" />
                    <stop offset=".7103" stop-color="#020202" />
                    <stop offset="1" />
                </radialGradient>
                <path fill="url(#r)" d="M59.4 74.7c0-8.4 6.8-15.2 15.2-15.2 8.4 0 15.2 6.8 15.2 15.2 0 8.4-6.8 15.2-15.2 15.2-8.4 0-15.2-6.8-15.2-15.2z" />
            </g>
        </mask>
        <g mask="url(#s)">
            <path d="M74.7 90.9V92v-1.1c.1-2.8.3-10.6.3-16.2s-.2-13.4-.2-16.2v-1.1 1.1c-.1 2.8-.2 10.6-.2 16.2-.2 5.6 0 13.4.1 16.2z" class="st7" />
            <defs>
                <filter id="t" width="3.3" height="34.4" x="73.1" y="57.5" filterUnits="userSpaceOnUse">
                    <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                </filter>
            </defs>
            <mask id="u" width="3.3" height="34.4" x="73.1" y="57.5" maskUnits="userSpaceOnUse">
                <g filter="url(#t)" />
            </mask>
            <path fill="#fff" d="M76.3 58.5v-1.1c-.1.3-.1.7-.2 1.1-.4 2.8-1.5 10.5-2 16.1-.5 5.6-.9 13.4-1 16.2v1.1s.1-.4.2-1.1c.4-2.8 1.5-10.5 2-16.1.4-5.5.8-13.3 1-16.2z" mask="url(#u)" opacity=".3" />
            <defs>
                <filter id="v" width="34.6" height=".5" x="57.4" y="74.4" filterUnits="userSpaceOnUse">
                    <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                </filter>
            </defs>
            <mask id="w" width="34.6" height=".5" x="57.4" y="74.4" maskUnits="userSpaceOnUse">
                <g filter="url(#v)" />
            </mask>
            <path fill="#fff" d="M58.5 74.7h-1.1 1.1c2.8.1 10.6.3 16.2.3s13.4-.2 16.2-.2H92h-1.1c-2.8-.1-10.6-.2-16.2-.2-5.6-.2-13.4 0-16.2.1z" mask="url(#w)" />
            <defs>
                <filter id="x" width="34.4" height="3.1" x="57.5" y="73.1" filterUnits="userSpaceOnUse">
                    <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                </filter>
            </defs>
            <mask id="y" width="34.4" height="3.1" x="57.5" y="73.1" maskUnits="userSpaceOnUse">
                <g filter="url(#x)" />
            </mask>
            <path fill="#fff" d="M58.5 73.2h-1.1c.3 0 .7.1 1.1.1 2.8.4 10.5 1.4 16.1 1.9 5.6.5 13.4.8 16.2 1h1.1s-.4-.1-1.1-.1c-2.8-.4-10.6-1.4-16.1-1.9-5.5-.6-13.4-.9-16.2-1z" mask="url(#y)" opacity=".3" />
        </g>
        <defs>
            <filter id="z" width="10.9" height="10.9" x="69.3" y="69.3" filterUnits="userSpaceOnUse">
                <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
            </filter>
        </defs>
        <mask id="B" width="10.9" height="10.9" x="69.3" y="69.3" maskUnits="userSpaceOnUse">
            <g filter="url(#z)">
                <radialGradient id="A" cx="204.6012" cy="67.1845" r="10.2597" gradientTransform="rotate(45 44.24819443 14.5660503) scale(.5292)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#fff" />
                    <stop offset=".07337778" stop-color="#d2d2d2" />
                    <stop offset=".1644" stop-color="#a1a1a1" />
                    <stop offset=".2605" stop-color="#767676" />
                    <stop offset=".3599" stop-color="#515151" />
                    <stop offset=".4635" stop-color="#343434" />
                    <stop offset=".5727" stop-color="#1d1d1d" />
                    <stop offset=".69" stop-color="#0d0d0d" />
                    <stop offset=".8211" stop-color="#030303" />
                    <stop offset=".9945" />
                </radialGradient>
                <path fill="url(#A)" d="M70.8 70.8c2.1-2.1 5.6-2.1 7.7 0 2.1 2.1 2.1 5.6 0 7.7-2.1 2.1-5.6 2.1-7.7 0-2.1-2.1-2.1-5.5 0-7.7z" />
            </g>
        </mask>
        <path fill="#fff" d="M70.8 70.8c2.1-2.1 5.6-2.1 7.7 0 2.1 2.1 2.1 5.6 0 7.7-2.1 2.1-5.6 2.1-7.7 0-2.1-2.1-2.1-5.5 0-7.7z" mask="url(#B)" />
        <defs>
            <filter id="C" width="25" height="25" x="69" y="11.7" filterUnits="userSpaceOnUse">
                <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
            </filter>
        </defs>
        <mask id="E" width="25" height="25" x="69" y="11.7" maskUnits="userSpaceOnUse">
            <g filter="url(#C)">
                <radialGradient id="D" cx="125.0097" cy="-607.2599" r="23.6459" gradientTransform="translate(15.4027 345.532) scale(.5292)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#fff" />
                    <stop offset=".04663591" stop-color="#d0d0d0" />
                    <stop offset=".09764109" stop-color="#a5a5a5" />
                    <stop offset=".1543" stop-color="#7d7d7d" />
                    <stop offset=".2152" stop-color="#5b5b5b" />
                    <stop offset=".2816" stop-color="#3f3f3f" />
                    <stop offset=".3556" stop-color="#282828" />
                    <stop offset=".4401" stop-color="#161616" />
                    <stop offset=".5419" stop-color="#090909" />
                    <stop offset=".6782" stop-color="#020202" />
                    <stop offset=".9945" />
                </radialGradient>
                <path fill="url(#D)" d="M69 24.2c0-6.9 5.6-12.5 12.5-12.5S94 17.3 94 24.2s-5.6 12.5-12.5 12.5S69 31.1 69 24.2z" />
            </g>
        </mask>
    </SVG>
);

export default Glitter;