import posterDesktop from './poster_3-desktop.jpg';
import posterMobile from './poster_3-mobile.jpg';
import subtitles from './subtitles3.vtt';

export const door1hej =
{
    "title": "Ett perfekt liv – Penny Parnevik",
    "doorNumber": "Lucka 3",
    "desription": "",
    "text": "Läs mer om psykisk ohälsa bland unga här.",
    "readMoreLink": "https://mind.se/kampanj/livet-kan-skava/",

    "meta": {
        "image": ""
    },
    "video": {
        "mobile": "https://player.vimeo.com/external/658761463.hd.mp4?s=8a30a41f6bcc952fbe12f5688aa15e89e9076430&download=1",
        "desktop": "https://player.vimeo.com/external/658640716.hd.mp4?s=99be578c1f9fc01a655d4df54d2a609c5c589780&download=1",
    },
    "poster": {
        mobile: posterMobile,
        desktop: posterDesktop
    },
    "subtitles": subtitles,
    "openDate": "27"
}

export default door1hej;
