import { useEffect, useState } from 'react';

import debounce from 'lodash/debounce';
import { getMinBreakpointValue } from '../utils/mediaqueries';

/**
 * Get a boolean if the window media query matches a specific breakpoint
 *
 * @version 1.0
 * @param {string} breakpointLabel - A breakpoint label to match: xs, sm, md etc.
 * @param {bool} updateOnResize - Should the function run on window resize
 * @returns {bool} - Returns true if window matches breakpoint
 */

export default (breakpointLabel, updateOnResize = true) => {
    const [isAbove, setIsAbove] = useState(true);

    useEffect(() => {
        const resizeHandler = () => {
            // Only update when necessary
            const matchIsAbove = window.matchMedia(`(min-width: ${getMinBreakpointValue(breakpointLabel)})`).matches;
            if (matchIsAbove !== isAbove) {
                setIsAbove(matchIsAbove);
            }
        };

        // Trigger resize on init
        resizeHandler();
        const debounceResizeHandler = debounce(resizeHandler, 250);

        // Active resize listener if that is necessary
        if (updateOnResize) {
            window.addEventListener('resize', debounceResizeHandler);
        }
        return () => {
            debounceResizeHandler.cancel();
            window.removeEventListener('resize', debounceResizeHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAbove]);

    return isAbove;
};
