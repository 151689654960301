import React from "react";
import styled from 'styled-components';
import { above } from '../utils/mediaqueries';

import Glitter from '../assets/icons/Glitter';

const GlitterWrapper = styled('div')`
    position: relative;
    height: 100%;
    width: 70%;

    ${above.lgTablet} {
        height: 100%;
        width: 100%;
    }
`;

const Spark = styled(Glitter)`
    position: absolute;
    top: ${({ top }) => `${top}`};
    left: ${({ left }) => `${left}`};
    transform-origin: center;
    animation-delay: ${({ delay }) => `${delay}`};
    animation: ${({ delay }) => `sparkle-anim 1.6s ${delay} infinite cubic-bezier(0.445, 0.05, 0.55, 0.95)`};

    @keyframes sparkle-anim {
        0%   {
            opacity: 0;
            transform: scale(0);
        }
        40%   {
            opacity: 0.7;
            transform: scale(1);

        }
        40%   {
            opacity: 0.7;
            transform: scale(2);

        }
        50% {
            opacity: 1;
            transform: scale(5);

        }
        55% {
            opacity: 1;
            transform: scale(2);

        }
        100% {
            opacity: 0;
            transform: scale(0);

        }
    }
`;

const Sparkles = () => (
    <GlitterWrapper>
        <Spark className="spark" top={'14%'} left={'13%'} delay={'0.2s'} />
        <Spark className="spark" top={'30%'} left={'20%'} delay={'1.6s'} />
        <Spark className="spark" top={'50%'} left={'34%'} delay={'1.3s'} />
        <Spark className="spark" top={'10%'} left={'36%'} delay={'0.25s'} />
        <Spark className="spark" top={'18%'} left={'28%'} delay={'0.9s'} />
        <Spark className="spark" top={'54%'} left={'39%'} delay={'0.55s'} />
        <Spark className="spark" top={'38%'} left={'47%'} delay={'1.42s'} />
        <Spark className="spark" top={'47%'} left={'55%'} delay={'1s'} />
        <Spark className="spark" top={'50%'} left={'47%'} delay={'1.1s'} />
        <Spark className="spark" top={'32%'} left={'20%'} delay={'0.2s'} />
        <Spark className="spark" top={'24%'} left={'32%'} delay={'1.5s'} />
        <Spark className="spark" top={'40%'} left={'21%'} delay={'0.4s'} />
        <Spark className="spark" top={'16%'} left={'45%'} delay={'0.2s'} />
        <Spark className="spark" top={'30%'} left={'35%'} delay={'1.1s'} />
        <Spark className="spark" top={'55%'} left={'13%'} delay={'1.3s'} />
    </GlitterWrapper>
);

export default Sparkles;