import React, { Fragment, useState, useRef, useEffect } from "react";
import PageMeta from "../components/metadata/PageMeta";
import styled from 'styled-components';
import bgImage from '../assets/images/hus-desktop-numbers.jpg';
import bgImageMobile from '../assets/images/hus-mobile-v4.jpg';
import colors from '../config/colors';
import HomePageTextSection from '../components/HomePageTextSection';
import { above, below } from '../utils/mediaqueries'
import { useHistory } from "react-router-dom";
import Footer from '../components/Footer';
import Sparkles from '../components/Sparkles';
import glitterImage from '../assets/images/allt_glitter.png';

const HomeWrapper = styled('div')`
    height: 1652px;
    grid-template-columns: repeat(12, 1fr);

    background-image: url(${bgImageMobile});
    background-position: center;
   
    background-repeat: no-repeat;
    color: ${colors.white};
    background-size: auto 1652px;

    ${above.smTablet} {
        height: 2252px;
        background-size: auto 2252px;
    }

    transform: ${({ coords }) => coords.left && coords.top ? `translate(${coords.left}px, ${coords.top}px) scale(3)` : `translate(0px, 0px) scale(1)`};
    transition: transform 1000ms cubic-bezier(0.445, 0.05, 0.55, 0.95);

    ${above.lgTablet} {
        background-size: cover;
        height: 100vh;
        display: flex;
        flex-direction: column;
        background-image: url(${bgImage}); 
        transform: ${({ coords }) => coords.left && coords.top ? `translate(${coords.left}px, ${coords.top}px) scale(6) ` : `translate(0px, 0px) scale(1)`};
    }
`;

const Content = styled('div')`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    height: 100%;
`;

const House = styled('div')`
    position: relative;
    width: 340px;
    grid-column: 2 / span 10;

    ${below.smPhone} {
        width: 100%;
        grid-column: 1 / span 12;
    }
    ${above.lgPhone} {
        grid-column: 4 / span 6;
    }
    ${above.smTablet}{
        width: 465px;
    }

    ${above.lgTablet} {
        grid-column: 6 / span 2;
        width: 100%;
    }
`;

const Doors = styled('div')`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    pointer-events:${({ stillCountingDown }) => !stillCountingDown ? "unset" : 'none'};

    ${above.smTablet} {
        top: 50%;
        left: 50%;
        transform: translate(-45%, -48%);
    } 

    ${above.lgTablet} {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -55%);
    }   

    &::after {
        content: "";
        display: block;
        width: 100%;
        padding-bottom: 215%;

        ${above.lgPhone} {
            padding-bottom: 215%;
        }

        ${above.lgTablet} {
            padding-bottom: 230%;
        } 
    }

    #door-5 {
        position: absolute;
        top: 0;
        left: 17.5%;
        width: 30%;
        height: 20%;

        ${above.smTablet} {
            width: 32%;
            left: 7vw;
        }
        ${above.lgTablet} {
            left: 10%;
            top: -2%;
        }
    }

    #door-2 {
        position: absolute;
        top: 24%;
        left: 6%;
        width: 36%;
        height: 22%;

        ${above.smTablet} {
            width: 33%;
            left: 4vw;
            top: 22%;
        }
        ${above.lgTablet} {
            width: 35%;
            left: 2%;
        }
     }
     
     #door-6 {
        position: absolute;
        top: 23%;
        right: 1%;
        width: 34%;
        height: 25%;

        ${above.smTablet} {
            width: 34%;
            right: 1vw;
            top: 22%;
        }

        ${above.lgTablet} {
            right: 0%;
        }
    }

     #door-1 {
        position: absolute;
        top: 52%;
        left: 9%;
        width: 32%;
        height: 25%;
        ${above.smTablet} {
            top: 50%;
            left: 3vw;
            width: 28%;
        }

        ${above.lgTablet} {
            left: 0%;
            width: 34%;
        }
     }

     #door-4 {
        position: absolute;
        top: 52%;
        left: 36%;
        width: 30%;
        height: 25%;

        ${above.smTablet} {
            top: 50%;
            left: 17vw;
            width: 31%;
        }
        ${above.lgTablet} {
            left: 32%;
            width: 32%;
        }
     }

     #door-7 {
        position: absolute;
        bottom: 3%;
        left: 9%;
        width: 32%;
        height: 20%;

        ${above.smTablet} {
            bottom: 3%;
            left: 3vw;
        }
        ${above.lgTablet} {
            left: 2%;
            bottom: 1%;
        }
    }
    
    #door-3 {
        position: absolute;
        bottom: 4%;
        right: 5%;
        width: 32%;
        height: 20%;

        ${above.smTablet} {
            bottom: 3%;
            right: 2.5vw;
        }
        ${above.lgTablet} {
            right: 2%;
            bottom: 2%;
        }
    }
`;


const Door = styled('div')`
    cursor: pointer;
    display: flex; 
    justify-content: center;
    align-items: center;
`;

const OverlayShade = styled('div')`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;

    ${below.lgTablet}{
        background: ${({ stillCountingDown }) => !stillCountingDown ? `
            linear-gradient(180deg,rgb(113 43 0) 0%,rgb(113 43 0 / 62%) 35%,rgba(113,43,0,0) 100%)
        `: `
            linear-gradient(180deg, rgba(113,43,0,0.9640231092436975) 2%, rgba(113,43,0,0.2777485994397759) 70%, rgba(113,43,0,0.6194852941176471) 95%);
`};
    }
    ${above.lgTablet} {
        height: 100%;
        background: radial-gradient(circle at 0% 53%,rgb(113 43 0 / 72%) 2%,transparent 70%) 0% 100% / 78% 100% no-repeat;
    }
    pointer-events: none;
`;

const Wrapper = styled('div')`
    width: 100%;
    height: ${({ stillCountingDown, fullHeight }) => fullHeight && stillCountingDown ? `${fullHeight}px` : '100%'};
    overflow: ${({ stillCountingDown }) => stillCountingDown ? 'hidden' : 'visible'};
    overflow-x: hidden;

    ${above.lgTablet} {
        height: ${({ fullHeight, stillCountingDown }) => fullHeight && stillCountingDown ? `${fullHeight}px` : '100%'};
        overflow-y: hidden;
    }
`;

const Snow = styled('div')`
    position: absolute;
    width: 100%;
    height: 100%;
  
    .snowing {
        position: absolute;
        top: -20px;
        border-radius: 50%;
        background-color: white;
        filter: blur(1px);
        animation: snowfall linear infinite;
    }

    @keyframes snowfall {
        to {
            transform: translateY(1652px);
        }
    }
    ${above.lgTablet} {
        @keyframes snowfall {
            to {
                transform: translateY(105vh);
            }
        }
    }

`;

const Home = () => {
    const history = useHistory();
    const wrapperRef = useRef(null)

    const calenderStartDate = "2021-12-27T00:00";
    const [myTime, setMyTime] = useState(new Date().getTime());
    const calenderStartDateNumber = new Date(calenderStartDate).getTime();
    const [stillCountingDown, setStillCountingDown] = useState(calenderStartDateNumber > myTime);

    const [fullHeight, setFullHeight] = useState(0);

    const [elementCoords, setElementCoords] = useState({
        top: 0,
        left: 0
    });

    const [doorStates, setDoorStates] = useState({
        door1: {
            openingDate: "2021-12-27",
            canBeOpened: false,
            slug: 'en-strimma-ljus',
            dateIsToday: false,
            dateComment: 27
        },
        door2: {
            openingDate: "2021-12-28",
            canBeOpened: false,
            slug: 'nu-ar-det-jul-igen',
            dateIsToday: false,
            dateComment: 28

        },
        door3: {
            openingDate: "2021-12-29",
            canBeOpened: false,
            slug: 'ett-perfekt-liv',
            dateIsToday: false,
            dateComment: 29

        },
        door4: {
            openingDate: "2021-12-30",
            canBeOpened: false,
            slug: 'ensam-ar-inte-stark',
            dateIsToday: false,
            dateComment: 30

        },
        door5: {
            openingDate: "2021-12-31",
            canBeOpened: false,
            slug: 'nar-ytan-spricker',
            dateIsToday: false,
            dateComment: 31
        },
        door6: {
            openingDate: "2022-01-01",
            canBeOpened: false,
            slug: 'resterna-av-ett-liv',
            dateIsToday: false,
            dateComment: 1

        },
        door7: {
            openingDate: "2022-01-02",
            canBeOpened: false,
            slug: 'ensam-bland-vanner',
            dateIsToday: false,
            dateComment: 2

        },
    });

    const displayDoorContent = (slug) => {
        history.push(`/lucka/${slug}`)

        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 2000)
    };

    const zoomToElement = (elementId, slug) => {
        const element = document.getElementById(elementId);

        const elementWidth = element.getBoundingClientRect().width / 2
        const elementHeight = element.getBoundingClientRect().height / 2
        const elementLeft = element.getBoundingClientRect().left
        const elementTop = element.getBoundingClientRect().top

        const elementOffsetTop = elementTop + window.pageYOffset
        const windowOffsetTopFromCenter = window.pageYOffset + (window.innerHeight / 2) //

        const elementDistanceToCenterY = windowOffsetTopFromCenter - (elementOffsetTop + elementHeight);
        const elementDistanceToCenterX = (window.innerWidth / 2 - elementLeft) - elementWidth

        const originY = elementOffsetTop + elementHeight;
        const originX = elementLeft + elementWidth;
        wrapperRef.current.style.pointerEvents = "none";

        wrapperRef.current.style.transformOrigin = `${originX}px ${originY}px`;
        // Set top and left state to manage new center
        setElementCoords({
            top: elementDistanceToCenterY,
            left: elementDistanceToCenterX
        })

        // Open Door
        setTimeout(() => {
            displayDoorContent(slug);
        }, 2000)
    }

    useEffect(() => {
        setInterval(() => tick(), 10000);

        return () => {
            clearInterval(tick())
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setNewTime();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myTime]);

    const tick = () => {
        setMyTime(new Date().getTime());
    }

    useEffect(() => {
        setFullHeight(window.innerHeight);

        window.addEventListener('resize', calculateWindowHeight);
        return () => window.removeEventListener('resize', calculateWindowHeight);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const calculateWindowHeight = () => {
        if (window.innerHeight !== fullHeight)
            setFullHeight(window.innerHeight);
    };

    const setNewTime = () => {
        const updatedDoorState = { ...doorStates }

        for (const door in updatedDoorState) {

            const updatedDoor = {
                ...updatedDoorState[door],
            };

            const formatDoorDate = `${updatedDoor.openingDate}T02:00:00`;
            const doorDate = new Date(formatDoorDate).getTime();
            const doorDay = new Date(formatDoorDate).getDate();
            const today = new Date().getDate();

            if (doorDate < myTime) {
                updatedDoor.canBeOpened = true;

                if (doorDay === today) {
                    updatedDoor.dateIsToday = true;

                } else {
                    updatedDoor.dateIsToday = false;
                }

                updatedDoorState[door] = updatedDoor;
            }
        }
        setDoorStates(updatedDoorState);
    };

    useEffect(() => {
        createSnowFlake()
    }, [])

    const createSnowFlake = () => {
        const snowContainer = document.getElementById('snowWrapper')
        for (let i = 0; i < 30; i++) {
            const snowflake = document.createElement('div');
            snowflake.classList.add('snowing');
            snowflake.style.width = Math.random() * 5 + 8 + 'px';
            snowflake.style.height = snowflake.style.width;
            snowflake.style.left = Math.random() * window.innerWidth + 'px';
            const top = Math.floor(Math.random() * 10) + 10
            snowflake.style.top = `-${top}px`;
            snowflake.style.animationDelay = Math.floor(Math.random() * 10) + 's';
            snowflake.style.animationDuration = Math.floor(Math.random() * 10) + 8 + 's';
            snowflake.style.opacity = Math.random();
            snowContainer.appendChild(snowflake);
        }
    }

    return (
        <Fragment>
            <PageMeta title="7 days of hell" />
            <Wrapper stillCountingDown={stillCountingDown} fullHeight={window.innerHeight}>
                <HomeWrapper ref={wrapperRef} coords={elementCoords}>
                    <OverlayShade stillCountingDown={stillCountingDown} />
                    <Content>
                        <Snow id="snowWrapper" />
                        <HomePageTextSection
                            setStillCountingDown={setStillCountingDown}
                            stillCountingDown={stillCountingDown}
                            calenderStartDate={calenderStartDate}
                        />
                        <House>
                            <Doors stillCountingDown={stillCountingDown}>
                                {Object.values(doorStates).map(({ canBeOpened, slug, dateIsToday }, i) => {
                                    const elementID = `door-${i + 1}`;
                                    return canBeOpened && (
                                        <Door key={i} id={elementID} onClick={() => zoomToElement(elementID, slug)}>
                                            {!stillCountingDown && dateIsToday ? (
                                                <Sparkles />
                                            ) : !stillCountingDown && <img alt="glitter, öppna dörr" src={glitterImage} width="100%" height="90%" />}
                                        </Door>
                                    )
                                })}
                            </Doors>
                        </House>
                    </Content>
                </HomeWrapper>
            </Wrapper>
            <Footer stillCountingDown={stillCountingDown} />
        </Fragment>
    );

}

export default Home;
