import posterDesktop from './poster_4-desktop.jpg';
import posterMobile from './poster_4-mobile.jpg';
import subtitles from './subtitles4.vtt'

export const door1hej =
{
    "title": "Ensam är inte stark – Marita, volontär på Mind",
    "doorNumber": "Lucka 4",
    "desription": "",
    "text": "Läs mer om Minds Föräldralinje",
    "readMoreLink": "https://mind.se/hitta-hjalp/foraldralinjen/",

    "meta": {
        "image": ""
    },
    "video": {
        "mobile": "https://player.vimeo.com/external/658743854.hd.mp4?s=1cd897978ee719de816883a5a78880f981174aa3&download=1",
        "desktop": "https://player.vimeo.com/external/658602835.hd.mp4?s=8f881256392255db15da37ad88b99e56337310fc&download=1",
    },
    "poster": {
        mobile: posterMobile,
        desktop: posterDesktop
    },
    "subtitles": subtitles,
    "openDate": "27"
}

export default door1hej;
