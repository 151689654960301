import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

const PageMeta = ({ title }) => (
    <Fragment>
        <Helmet>
            <meta charSet="utf-8" />
            {title && <title>{title}</title>}
        </Helmet>
    </Fragment>
);
export default PageMeta;