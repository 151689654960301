import React, { useState, Fragment } from "react";
import styled from 'styled-components';
import { above } from '../utils/mediaqueries';
import Button from './Button';
import Link from './Link';
import SharePopup from './SharePopup';

const Wrapper = styled('div')`
    width: 100%;

    ${above.lgTablet} {
        display: flex;
        justify-content: center;
    }
`;

const StyledButton = styled(Button)`
    margin-bottom: 14px;
    height: 48px;

    ${above.lgTablet} {
        height: 36px;
        margin: 23px;
        line-height: 12px;
    }

    :hover {
        opacity: 0.8;
    }

    transition: opacity 250ms ease;

`;

const StyledLink = styled(Link)`
    margin-bottom: 14px;
    height: 48px;

    ${above.lgTablet} {
        height: 36px;
        margin: 23px;

        :last-of-type {
            margin-bottom: 40px;
        }
    }

    :hover {
        opacity: 0.8;
    }

    transition: opacity 250ms ease;

`;

const ButtonGroup = () => {
    const [showSharePopup, setShowSharePopup] = useState(false);
    const shareContent = () => {
        setShowSharePopup(!showSharePopup)
    }

    return (
        <Fragment>
            <SharePopup showPopup={showSharePopup} onOutsideClick={() => setShowSharePopup(false)} />
            <Wrapper>
                <StyledButton onClick={shareContent}>Dela kalendern</StyledButton>
                <StyledLink styledAsButton to="https://mind.adoveo.com/start/TkRSaFpHOTJaVzg9/?location=7daysofhell">Swisha en gåva</StyledLink>
                <StyledLink styledAsButton to="https://mind.se/bli-volontar/">Bli volontär</StyledLink>
            </Wrapper>
        </Fragment>
    );
};

export default ButtonGroup;