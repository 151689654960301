import React from "react";
import styled from 'styled-components';
import colors from '../config/colors';
import { above } from '../utils/mediaqueries';

const BaseButton = styled('button')`
    display: block;

    width: 100%;

    padding: 12px 0px;

    border: 0;
    background: ${colors.orange};
    color: ${colors.white};

    font-weight: 600;
    font-size: 1.7rem;
    cursor: pointer;
    user-select: none;

    ${above.lgTablet} {
        width: 158px;
        font-size: 1.4rem;
    }

`;


const Button = ({ children, onClick, ...props }) => (
    <BaseButton onClick={onClick} {...props}>{children}</BaseButton>
);

export default Button;