import React from 'react';
import SVG from './base/SVG';
import Path from './base/Path';

const Twitter = () => (
    <SVG width="42px" data-name="Lager 1" viewBox="0 0 200 200">
        <Path d="M100.33.8A99.21 99.21 0 0 0 1.12 100a99.21 99.21 0 0 0 198.41 0A99.2 99.2 0 0 0 100.33.8Zm42 77.65C143.73 109 121 143 80.72 143a61.43 61.43 0 0 1-33.25-9.74 43.58 43.58 0 0 0 32.11-9 21.72 21.72 0 0 1-20.26-15.07 21.71 21.71 0 0 0 9.79-.36 21.71 21.71 0 0 1-17.39-21.57A21.5 21.5 0 0 0 61.55 90a21.72 21.72 0 0 1-6.72-29 61.57 61.57 0 0 0 44.7 22.66 21.7 21.7 0 0 1 37-19.78 43.25 43.25 0 0 0 13.77-5.27 21.75 21.75 0 0 1-9.53 12 43.4 43.4 0 0 0 12.45-3.41 43.93 43.93 0 0 1-10.86 11.25Z" />
    </SVG>
);

export default Twitter;
