import door1 from "./doors/door1";
import door2 from "./doors/door2";
import door3 from "./doors/door3";
import door4 from "./doors/door4";
import door5 from "./doors/door5";
import door6 from "./doors/door6";
import door7 from "./doors/door7";

const paths = {
    "/lucka/en-strimma-ljus": door1,
    "/lucka/nu-ar-det-jul-igen": door2,
    "/lucka/ett-perfekt-liv": door3,
    "/lucka/ensam-ar-inte-stark": door4,
    "/lucka/nar-ytan-spricker": door5,
    "/lucka/resterna-av-ett-liv": door6,
    "/lucka/ensam-bland-vanner": door7,
};

const pageContent = path => {
    return paths[path] || null;
};

export default pageContent;
