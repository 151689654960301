import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import Link from './Link';
import Button from './Button';

const CookieWrapper = styled('div')`
    position: fixed;
    background: white;
    z-index: 1000000;
    width: 100%;
    bottom: 0;
    left: 0;
`;

const ContentWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 30px;        
    text-align: center;
`;

const StyledLink = styled(Link)`
    color: black;
`;

const TextWrapper = styled('div')`
    display: flex;
    justify-content: center;
    flex-direction: column;

    h2 {
        font-size: 16px;
        margin: 0 0 12px;
    }

    p {

        font-size: 16px;
        line-height: 20px;
        margin: 0 0 16px;

    }
`;

const ConsentCookie = () => {
    const [cookie, setCookie] = useState(true);

    useEffect(() => {
        const consentCookie = Cookies.get('cookie_consent') !== undefined;

        if (consentCookie) {
            setCookie(false);
        }

    }, [cookie])

    const handleCookie = () => {
        Cookies.set('cookie_consent', 1, { expires: 28, sameSite: 'Strict' });
        setCookie(false);
    };

    return (
        cookie && (
            <CookieWrapper>
                <ContentWrapper>
                    <TextWrapper>
                        <h2>Cookies</h2>
                        <p>
                            Vi använder cookies för att förbättra din upplevelse. Genom att fortsätta använda sidan godkänner du vår  {' '}
                            <StyledLink to="https://mind.se/integritetspolicy/">
                                integritetspolicy
                            </StyledLink>
                        </p>
                    </TextWrapper>
                    <Button onClick={handleCookie}>Stäng</Button>
                </ContentWrapper>
            </CookieWrapper>
        )

    );
}

export default ConsentCookie;
