import React from "react";
import SVG from "./base/SVG";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledSVG = styled(SVG)`
  .st0 {
    fill: #ec6608;
  }
  .st1 {
    fill: #ffffff;
  }
`;

const MindOrangeLogo = () => {
  return (
    <StyledSVG
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 904.3 695.3"
      space="preserve"
        height="120px"
    >
      <g>
        <polygon
          class="st0"
          points="279.5,186.2 279.4,186.1 227.8,289.9 176.3,186.1 176.1,186.2 176.1,186.1 134.9,186.1 134.9,348.5 
		176.1,348.5 176.1,277.7 211.4,348.5 211.5,348.5 211.5,348.5 244.2,348.5 279.5,277.9 279.5,348.5 320.7,348.5 320.7,186.1 
		279.5,186.1 	"
        />
        <path
          class="st0"
          d="M456.8,379.2c-21.1-18.4-51.5-18.9-71.7-19.1h-52.7v162.4h65.1c17.3-0.2,63.5-1.1,78.7-51.3
		c2.1-6.9,4.2-17.3,4.2-31.3C480.4,417.2,473.9,394.4,456.8,379.2z M386.8,489.9h-12.6v-95.5h16.6c12.6,0,28.6-0.2,39.8,14.5
		c7.5,10.1,8.7,21.4,8.7,32.7C439.2,488.3,408.8,489.4,386.8,489.9z"
        />
        <polygon
          class="st0"
          points="279.5,464.6 176.1,360.1 176.1,360.1 134.9,360.1 134.9,522.6 176.1,522.6 176.1,418.2 279.5,522.6 
		279.5,522.6 320.7,522.6 320.7,360.1 279.5,360.1 	"
        />
        <rect x="332.4" y="186.1" class="st0" width="41.2" height="162.4" />
        <path
          class="st0"
          d="M353,173.6c14.1,0,25.6-11.3,25.6-25.2c0-13.9-11.5-25.2-25.6-25.2c-14.1,0-25.6,11.3-25.6,25.2
		C327.4,162.3,338.9,173.6,353,173.6z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M545.9,524.2l9.4-34.5H546c-35.1,0-35.5-35.1-35.5-35.5v-58.5c0-35.1,35.1-35.5,35.5-35.5h189
		c35.1,0,35.5,35.1,35.5,35.5v58.5c0,35.1-35.1,35.5-35.5,35.5H591.6L545.9,524.2z M546,367.3c-1.2,0-28.5,0.5-28.5,28.5v58.5
		c0,1.2,0.5,28.5,28.5,28.5h18.5l-6.4,23.5l31.2-23.5h145.6c1.2,0,28.5-0.5,28.5-28.5v-58.5c0-1.2-0.5-28.5-28.5-28.5H546z"
        />
      </g>
      <path
        class="st0"
        d="M406.7,287.6v-69.5c0,0,0-32,32-32h300c0,0,32,0,32,32v69.5c0,0,0,32-32,32h-14l7.9,29l-38.5-29H438.6
	C438.6,319.6,406.7,319.6,406.7,287.6"
      />
      <g>
        <path
          class="st1"
          d="M446.7,231h8.6l8.5,27.5l8.5-27.5h7.6l-11.9,35.8h-9.3L446.7,231z"
        />
        <path
          class="st1"
          d="M478.4,259.5c0-6.1,5.6-8.4,13.6-8.4h3V250c0-3.1-1-4.8-4.3-4.8c-2.9,0-4.2,1.5-4.5,3.7h-6.9
		c0.5-6.2,5.4-9,11.8-9s11,2.6,11,9.7v17.1H495v-3.2c-1.5,2.1-3.8,3.7-7.9,3.7C482.4,267.3,478.4,265,478.4,259.5z M494.9,257.6
		v-2.2h-2.8c-4.2,0-6.7,0.9-6.7,3.7c0,1.9,1.1,3.1,3.8,3.1C492.5,262.2,494.9,260.5,494.9,257.6z"
        />
        <path
          class="st1"
          d="M506.6,240.6h7.3v5c1.6-3.5,4.2-5.4,8.5-5.4v6.8c-5.4,0-8.5,1.7-8.5,6.7v13.1h-7.3V240.6z"
        />
        <path
          class="st1"
          d="M522.3,270.7c2.5-0.2,3.5-1.4,3.5-3.5v-26.7h7.2v26.6c0,5.5-2.5,8.8-10.7,8.9V270.7z M525.2,233.1
		c0-2.3,1.8-4,4.1-4c2.4,0,4.2,1.7,4.2,4c0,2.2-1.8,4-4.2,4C527,237,525.2,235.3,525.2,233.1z"
        />
        <path
          class="st1"
          d="M537.2,254v-0.4c0-8.3,5.9-13.6,13.5-13.6c6.8,0,12.8,4,12.8,13.3v2h-18.9c0.2,4.4,2.5,6.9,6.5,6.9
		c3.4,0,5-1.5,5.5-3.7h6.9c-0.8,5.7-5.4,8.8-12.5,8.8C543,267.3,537.2,262.3,537.2,254z M556.4,250.8c-0.2-4-2.3-5.9-5.7-5.9
		c-3.2,0-5.5,2.2-6,5.9H556.4z"
        />
        <path
          class="st1"
          d="M577.4,258.5h6.8c0.3,2.4,1.5,3.7,4.6,3.7c2.8,0,4.1-1,4.1-2.9c0-1.8-1.5-2.5-5.3-3.1c-7-1-9.7-3.1-9.7-8.2
		c0-5.5,5-8.2,10.4-8.2c5.8,0,10.2,2.1,10.8,8.1h-6.7c-0.4-2.1-1.6-3.1-4.1-3.1c-2.4,0-3.7,1.1-3.7,2.7c0,1.6,1.2,2.3,5.1,2.9
		c6.6,1,10.2,2.6,10.2,8.2c0,5.7-4.1,8.6-11,8.6C581.7,267.3,577.6,264.2,577.4,258.5z"
        />
        <path
          class="st1"
          d="M602.6,259.5c0-6.1,5.6-8.4,13.6-8.4h3V250c0-3.1-1-4.8-4.2-4.8c-2.9,0-4.2,1.5-4.5,3.7h-6.9
		c0.5-6.2,5.4-9,11.8-9s11,2.6,11,9.7v17.1h-7v-3.2c-1.5,2.1-3.8,3.7-7.9,3.7C606.6,267.3,602.6,265,602.6,259.5z M619.1,257.6v-2.2
		h-2.8c-4.2,0-6.7,0.9-6.7,3.7c0,1.9,1.2,3.1,3.8,3.1C616.7,262.2,619.1,260.5,619.1,257.6z"
        />
        <path
          class="st1"
          d="M630.8,240.6h7.2v4c1.3-2.5,4.1-4.6,8-4.6c3.5,0,6.1,1.4,7.3,4.6c2.1-3.2,5.5-4.6,8.8-4.6c4.9,0,8.6,3,8.6,10
		v16.8h-7.2v-16c0-3.4-1.5-4.9-4.1-4.9c-2.8,0-5,1.8-5,5.4v15.5h-7.2v-16c0-3.4-1.6-4.9-4.1-4.9c-2.8,0-5.1,1.8-5.1,5.4v15.5h-7.2
		V240.6z"
        />
        <path
          class="st1"
          d="M677.1,259v-13.3h-3.3v-5.1h3.3v-5.7h7.2v5.7h5.5v5.1h-5.5v12.6c0,2.1,1,3.1,2.9,3.1c1.1,0,1.9-0.1,2.8-0.5
		v5.6c-1,0.4-2.5,0.7-4.4,0.7C680,267.2,677.1,264.4,677.1,259z"
        />
        <path
          class="st1"
          d="M692.6,259.5c0-6.1,5.6-8.4,13.6-8.4h3V250c0-3.1-1-4.8-4.2-4.8c-2.9,0-4.2,1.5-4.5,3.7h-6.9
		c0.5-6.2,5.4-9,11.8-9s11,2.6,11,9.7v17.1h-7v-3.2c-1.5,2.1-3.8,3.7-7.9,3.7C696.6,267.3,692.6,265,692.6,259.5z M709.1,257.6v-2.2
		h-2.8c-4.2,0-6.7,0.9-6.7,3.7c0,1.9,1.2,3.1,3.8,3.1C706.7,262.2,709.1,260.5,709.1,257.6z"
        />
        <path class="st1" d="M720.9,228.7h7.2v38h-7.2V228.7z" />
      </g>
      <g>
        <path
          class="st0"
          d="M567.5,412h7.3v5c1.6-3.5,4.2-5.4,8.5-5.4v6.8c-5.4,0-8.5,1.7-8.5,6.7v13.1h-7.3V412z"
        />
        <path
          class="st0"
          d="M584.2,430.9c0-6.1,5.6-8.4,13.6-8.4h3v-1c0-3.1-1-4.8-4.3-4.8c-2.9,0-4.2,1.5-4.5,3.7h-6.9
		c0.5-6.2,5.4-9,11.8-9s11,2.6,11,9.7v17.1h-7.1V435c-1.5,2.1-3.8,3.7-7.9,3.7C588.2,438.7,584.2,436.4,584.2,430.9z M588.1,404.6
		c0-2,1.6-3.6,3.7-3.6s3.7,1.6,3.7,3.6s-1.6,3.6-3.7,3.6S588.1,406.6,588.1,404.6z M600.7,429v-2.2h-2.8c-4.2,0-6.7,0.9-6.7,3.7
		c0,1.9,1.1,3.1,3.8,3.1C598.3,433.7,600.7,431.9,600.7,429z M598.3,404.6c0-2,1.6-3.6,3.7-3.6c2,0,3.7,1.6,3.7,3.6
		s-1.7,3.6-3.7,3.6C599.9,408.2,598.3,406.6,598.3,404.6z"
        />
        <path
          class="st0"
          d="M612.4,400.2h7.3v22.9l8.4-11h7.7l-9.5,11.9l10.3,14.3h-8.2l-8.7-12.4v12.4h-7.3V400.2z"
        />
        <path
          class="st0"
          d="M639.4,412h7.3v4.1c1.3-2.6,4.2-4.8,8.5-4.8c5.2,0,8.8,3.1,8.8,10.1v16.7h-7.2v-15.6c0-3.5-1.4-5.2-4.6-5.2
		c-3.1,0-5.5,1.9-5.5,5.8v15.1h-7.3V412z"
        />
        <path
          class="st0"
          d="M667,430.9c0-6.1,5.6-8.4,13.6-8.4h3v-1c0-3.1-1-4.8-4.3-4.8c-2.9,0-4.2,1.5-4.5,3.7H668c0.5-6.2,5.4-9,11.8-9
		s11,2.6,11,9.7v17.1h-7.1V435c-1.5,2.1-3.8,3.7-7.9,3.7C671,438.7,667,436.4,667,430.9z M683.5,429v-2.2h-2.8
		c-4.2,0-6.7,0.9-6.7,3.7c0,1.9,1.1,3.1,3.8,3.1C681.1,433.7,683.5,431.9,683.5,429z"
        />
        <path
          class="st0"
          d="M693.6,430h6.8c0.3,2.4,1.5,3.7,4.6,3.7c2.8,0,4.1-1,4.1-2.8s-1.6-2.5-5.3-3.1c-7-1-9.7-3-9.7-8.2
		c0-5.5,5-8.2,10.4-8.2c5.8,0,10.2,2.1,10.8,8.1h-6.7c-0.4-2.2-1.6-3.2-4.1-3.2c-2.3,0-3.7,1.1-3.7,2.7c0,1.7,1.2,2.3,5.1,2.9
		c6.5,1,10.2,2.6,10.2,8.2c0,5.7-4.1,8.6-11,8.6C698,438.7,693.9,435.6,693.6,430z"
        />
      </g>
    </StyledSVG>
  );
};

MindOrangeLogo.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  viewBox: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

MindOrangeLogo.defaultProps = {
  viewBox: "0 0 74.6 47.8",
};

export default MindOrangeLogo;
