import posterDesktop from './poster_5-desktop.jpg';
import posterMobile from './poster_5-mobile.jpg';
import subtitles from './subtitles5.vtt';

export const door1hej =
{
    "title": "När ytan spricker – Nadim Ghazale",
    "doorNumber": "Lucka 5",
    "desription": "",
    "text": "Ta del av ett samtal om machonorm och känslor",
    "readMoreLink": "https://mind.se/aktuellt/kanslor-och-machonormen-pa-ur-play/",

    "meta": {
        "image": ""
    },
    "video": {
        "mobile": "https://player.vimeo.com/external/658870780.hd.mp4?s=471ccf01f42ae8559dce87bacfc5bc2ad70ff74d&download=1",
        "desktop": "https://player.vimeo.com/external/658654727.hd.mp4?s=c27483b3ab25c776468f15ee810c1abb52ed9f48&download=1",
    },
    "poster": {
        mobile: posterMobile,
        desktop: posterDesktop
    },
    "subtitles": subtitles,
    "openDate": "27"
}

export default door1hej;
