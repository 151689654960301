import posterDesktop from './poster_6-desktop.jpg';
import posterMobile from './poster_6-mobile.jpg';
import subtitles from './subtitles6.vtt';
export const door1hej =
{
    "title": "Resterna av ett liv – Malin Persson Giolito",
    "doorNumber": "Lucka 6",
    "desription": "",
    "text": "Läs mer om Minds Självmordslinje – stöd för den som har självmordstankar eller för anhöriga till någon som mår dåligt",
    "readMoreLink": "https://mind.se/hitta-hjalp/sjalvmordslinjen/",

    "meta": {
        "image": ""
    },
    "video": {
        "mobile": "https://player.vimeo.com/external/658894537.hd.mp4?s=a2c782d78a885f2c1f2666563052d45a35507795&download=1",
        "desktop": "https://player.vimeo.com/external/658668225.hd.mp4?s=925a4d2b65bd91296e04cf93dcaf5a1524fd8edf&download=1",
    },
    "poster": {
        mobile: posterMobile,
        desktop: posterDesktop
    },
    "subtitles": subtitles,
    "openDate": "27"
}

export default door1hej;
