import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from 'styled-components';
import { above } from '../utils/mediaqueries';
import colors from '../config/colors';
import pageContent from "../pageContet";
import PageMeta from "../components/metadata/PageMeta";
import BackgroundVideo from "../components/BackgroundVideo";
import ButtonGroup from "../components/ButtonGroup";
import MindOrangeLogo from '../assets/icons/MindOrangeLogo';
import ArrowWhite from '../assets/icons/ArrowWhite';
import Close from '../assets/icons/Close';
import useAboveBreakpoint from '../hooks/useAboveBreakpoint';
import Link from '../components/Link';

const GoBackButton = styled('div')`
    position: absolute;
    top: 56px;
    left: 24px;
    width: 21px;
    cursor: pointer;
    z-index: 3;
    opacity: 1;
    transform: rotate(270deg);

    :hover {
        opacity: 0.8;
    }

    ${above.lgTablet} {
        top: 40px;
    }

    transition: opacity 250ms ease;
`;

const DoorText = styled('h2')`
    position: absolute;
    top: 87px;
    right: 0;
    left: 0;

    z-index: 1;
    color: white;
    font-weight: 600;

    ${above.lgTablet} {
        top: 56px;
    }
`;

const CTAOverlay = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100%;
    height: auto;
    width: 100%;

    background: rgb(0 0 0 / 70%);
    opacity: 0;
    visibility: hidden;
    transition: all 800ms ease;

    ${({ videoIsDone }) => videoIsDone && `
        visibility: visible;
        opacity: 1;
    `};
`;

const CTAContent = styled('div')`
    position: relative;
    color: white;
    height: 100vh;

    span {
        font-size: 18px;
        margin-top: 32px;
        display: block;

        ${above.lgTablet} {
            margin-top: 62px;
        }
    }
    
    .close-desktop {
        display: none;
    }

    ${above.lgTablet} {
        .close-mobile {
            display: none;
        }
        .close-desktop {
            display: block;
        }
    }
`;

const Paragraph = styled('p')`
    font-size: 17px;
    line-height: 22px;
    margin: 0 0 40px;

    ${above.lgTablet} {
        margin: 0 0 56px;
        font-size: 18px;
    }
`;


const TextLink = styled('a')`
    font-size: 12px;
    color: ${colors.white};
    display: block;
    margin-top: 12px;
    text-underline-offset: 2px;

    :hover {
        opacity: 0.8;
    }

    transition: opacity 250ms ease;
`;

const ScrollDown = styled('div')`
    width: 100%;
    position: relative;
    margin-top: -1px;
    opacity: 1;

    transition: opacity 250ms ease;

    height: 74px;
    background: black;
    cursor: pointer;
   
    pointer-events: none;

    span {
        display: block;
        color: white;
    }
`;

const StyledArrowWhite = styled(ArrowWhite)`
    transform: rotate(180deg);
`;

const ArrowWrapper = styled('div')`
    padding: 14px;
    span {
        margin-bottom: 8px;
        font-size: 17px;
        font-weight: 600;
    }
`;

const ButtonWrapper = styled('div')`
    padding: 20px 48px;
    background: white;
`;

const VideoWrapper = styled('div')`
    height:calc(87vh - 74px);
    ${above.lgTablet} {
       height: calc(100vh - 72px);
    }
`;

const MobileLogo = styled(Link)`
    ${above.lgTablet}{
        display: none;
    }
`;

const CenteredContent = styled('div')`
   position: absolute;
   top: 400px;
   left: 50%;
   transform: translate(-50%, -50%);

    width: 100%;
    padding: 0 48px 0;

    ${above.lgPhone} {
        top: 50%;
        padding: 80px;
    }

    ${above.lgTablet} {
        top: 50%;
        padding: 0;
    }
`;

const StyledClose = styled(Close)`
    position: absolute;
    top: 56px;
    right: 25px;
    z-index: 20;

    ${above.lgTablet} {
        top: 32px;
        right: 32px;
    }
`;
const Door = () => {
    const location = useLocation();
    const history = useHistory();
    const content = pageContent(location.pathname);
    const { title, video, doorNumber, poster, text, readMoreLink, subtitles } = content;

    const [videoIsDone, setVideoIsDone] = useState(false)
    const isAboveLgTablet = useAboveBreakpoint('lgTablet')

    const onVideoEnd = () => {
        setVideoIsDone(true);
    };

    return (
        <div style={{ height: '100%' }}>
            <PageMeta title={`7 days of hell - ${title}`} />
            <GoBackButton onClick={history.goBack}>
                <ArrowWhite />
            </GoBackButton>
            {isAboveLgTablet &&
                <VideoWrapper>
                    <BackgroundVideo subtitles={subtitles} poster={poster.desktop} src={video.desktop} onEnded={() => onVideoEnd()} />
                </VideoWrapper>}
            {
                !isAboveLgTablet &&
                <VideoWrapper>
                    <BackgroundVideo subtitles={subtitles} poster={poster.mobile} src={video.mobile} onEnded={() => onVideoEnd()} />
                </VideoWrapper>
            }
            <ScrollDown>
                <ArrowWrapper>
                    <span>Så här kan du bidra</span>
                    <StyledArrowWhite />
                </ArrowWrapper>
            </ScrollDown>
            <ButtonWrapper>
                <ButtonGroup />
                <MobileLogo to="https://mind.se/">
                    <MindOrangeLogo />
                </MobileLogo>
            </ButtonWrapper>
            <CTAOverlay videoIsDone={videoIsDone}>
                <CTAContent>
                    <DoorText>
                        {doorNumber}
                    </DoorText>
                    <div style={{ cursor: 'pointer' }} onClick={() => setVideoIsDone(false)}>
                        <StyledClose className="close-mobile" width="24px" height="24px" />
                        <StyledClose className="close-desktop" width="28px" height="28px" />
                    </div>
                    <CenteredContent>
                        <Paragraph>Hur vill du stödja Minds arbete för att minska psykiskt lidande och självmord</Paragraph>
                        <ButtonGroup />
                        <span>
                            <TextLink
                                href={readMoreLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ fontSize: '18px', display: "initial" }}
                            >
                                {text}
                            </TextLink>
                        </span>
                        <MobileLogo to="https://mind.se/">
                            <MindOrangeLogo />
                        </MobileLogo>
                    </CenteredContent>
                </CTAContent>
            </CTAOverlay>
        </div >
    );
};

export default Door;