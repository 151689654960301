import React, { useState } from "react";
import styled from 'styled-components';
import { above, below } from '../utils/mediaqueries';
import ButtonGroup from "./ButtonGroup";
import ArrowBlack from '../assets/icons/ArrowBlack';
import MindOrangeLogo from '../assets/icons/MindOrangeLogo';
import Link from './Link';

const Wrapper = styled('div')`
    ${below.lgTablet} {
        position: ${({ stillCountingDown }) => !stillCountingDown && 'absolute'};
        bottom: ${({ stillCountingDown }) => stillCountingDown ? '0' : 'unset'};
        height:${({ stillCountingDown }) => stillCountingDown && '100%'};
        left: 0;
        right: 0;
        
        .footer-arrow-mobile {
            color: white;

            svg {
                fill: white;
            }
        }

        .footer-arrow-desktop {
            display: none;
        }
    }
    ${above.lgTablet} {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        .footer-arrow-desktop {
            display: block;
            svg {
                fill: ${({ showFooter }) => showFooter ? 'black' : 'white'};
            }
            span {
                color: ${({ showFooter }) => showFooter ? 'black' : 'white'};
            }
        }
        .footer-arrow-mobile {
            display: none;
        }
    }

    span {
        display: block;
        margin-top: 8px;

    }
`;

const ButtonGroupWhiteBackground = styled('div')`
    display: flex;
    justify-content: center;
    width: 100%;

    padding-top: 0;
    padding-left: 48px;
    padding-right: 48px;
    
    ${below.lgTablet} {
        flex-direction: column;
        align-items: center;
        padding-top: 40px;
    

        ${({ stillCountingDown, showFooter }) => !stillCountingDown ? `
            position: absolute;
            bottom: 100px;
            height: 100%;
            background: transparent;
            visibility: visible;
            overflow: unset;
            bottom: 200px;
            align-items: center;

        ` : showFooter && `
            visibility: visible;
            height: 340px;
            padding-top: 20px;
        `};
    }

    ${above.lgTablet} {
        height: 0;
        align-items: flex-end;
        background: white;
        visibility: hidden;
        overflow: hidden;

        ${({ showFooter }) => showFooter && `
            visibility: visible;
            height: 165px;
        `}

    }

    transition: all 250ms ease;
`;


const ArrowWrapper = styled('div')`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    color: white;
    ${({ showFooter }) => showFooter && `
        margin-bottom: 240px;
    
    `}

    ${above.lgTablet} {
        ${({ showFooter }) => showFooter ? `
            margin-bottom: 102px;
        ` : `
            padding-bottom: 24px;

        `}
    }

    transition: margin-bottom 250ms ease;

    span {
        
        ${below.lgTablet} {
          max-width: 200px;
          margin: 0 auto 8px;
        }
        font-size: 17px;
        font-weight: 600;
    }

    ${below.lgTablet} {
        ${({ stillCountingDown }) => !stillCountingDown && 'display: none'};
    }
`;


const StyledArrowBlack = styled(ArrowBlack)`
    transform: rotate(180deg);
    ${above.lgTablet} {
        transform: ${({ showFooter }) => showFooter ? 'rotate(180deg)' : 'rotate(0deg)'};
    }

    transition: transform 200ms ease;

`;

const MobileLogo = styled(Link)`
    ${above.lgTablet}{
        display: none;
    }
`;


const Footer = ({ stillCountingDown }) => {
    const [show, toogle] = useState(false);

    return (
        <Wrapper stillCountingDown={stillCountingDown} showFooter={show}>
            <ArrowWrapper className="footer-arrow-desktop" showFooter={show} stillCountingDown={stillCountingDown} onClick={() => toogle(!show)}>
                <StyledArrowBlack showFooter={show} />
                <span>Så här kan du stödja Minds arbete</span>
            </ArrowWrapper>
            <ArrowWrapper className="footer-arrow-mobile" showFooter={show} stillCountingDown={stillCountingDown}>
                <span>Så här kan du stödja Minds arbete</span>
                <StyledArrowBlack showFooter={show} />
            </ArrowWrapper>
            <ButtonGroupWhiteBackground stillCountingDown={stillCountingDown} showFooter={show}>
                <ButtonGroup />
                <MobileLogo to="https://mind.se/">
                    <MindOrangeLogo />
                </MobileLogo>
            </ButtonGroupWhiteBackground>
        </Wrapper>
    );
};

export default Footer;