import React, { Fragment } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Door from "./pages/Door";
import Home from "./pages/Home";
import MindSmallLogo from './assets/icons/MindSmallLogo';
import styled from 'styled-components';
import { above } from './utils/mediaqueries';
import MindLargeLogo from './assets/icons/MindLargeLogo';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Link from "./components/Link";
import ConsentCookie from './components/ConsentCookie';
import ScrollToTop from './components/ScrollToTop';

const Header = styled('header')`
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);
    .logo-mobile {
        position: relative;
        top: 24px;
    }

    .logo-desktop {
        display: none;
    }

    ${above.lgTablet} {        
        display: flex;
        justify-content: flex-start;
        left: 0;
        transform: translateX(0);

        .logo-mobile {
            display: none;
        }

        .logo-desktop {
            display: block;
            padding: 12px 0 0 14px;
        }
    }
`;
const Site = () => {
    const location = useLocation();

    return (
        <Fragment>
            <Header>
                <Link internalLink to="/">
                {location.pathname === "/" && <MindSmallLogo className="logo-mobile" width="72px" />}
                    {location.pathname === "/" && <MindSmallLogo className="logo-desktop" width="120px" />}
                </Link>
            </Header>
            <TransitionGroup>
                <ScrollToTop />
                <CSSTransition key={location.key} timeout={{ enter: 2000, exit: 500 }} classNames={'fade'}>
                    <Switch location={location}>
                        <Route exact path="/" component={Home}></Route>
                        <Route exact path="/lucka/:slug" component={Door} />
                    </Switch>
                </CSSTransition>
            </TransitionGroup>
            <ConsentCookie />
        </Fragment>
    );
};

export default Site