import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { above } from '../utils/mediaqueries';

const Wrapper = styled('div')`
    width: 100%;
`;

const Column = styled('div')`
    display: flex;
    flex-direction: column;
    min-width: 60px;

    flex: 0 0 auto;
    align-items: center;

    ${above.lgTablet} {
        justify-content: flex-end;
    }
`;

const Number = styled('div')`
    font-size: 4.4rem;
    font-weight: 600;
`;

const DateType = styled('div')`
    font-size: 1rem;
`;

const Colun = styled('div')`
    font-size: 4.4rem;
    font-weight: 600;
`;

const CountdownRow = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: center;

    ${above.lgTablet} {
        justify-content: flex-end;
    }
`;

const Coundown = ({ dateToCountdown, setStillCountingDown }) => {
    const countdownDate = new Date(dateToCountdown).getTime();

    const [timeLeft, setTimeLeft] = useState({
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
    })

    useEffect(() => {
        const currentTime = new Date().getTime();

        if (countdownDate > currentTime) {
            setInterval(() => setNewTime(), 1000);
        }

        return () => {
            clearInterval(setNewTime())
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const setNewTime = () => {
        if (countdownDate) {
            const currentTime = new Date().getTime();
            const distanceToDate = countdownDate - currentTime;

            if (countdownDate > currentTime) {

                let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
                let hours = Math.floor(
                    (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
                );
                let minutes = Math.floor(
                    (distanceToDate % (1000 * 60 * 60)) / (1000 * 60),
                );
                let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

                const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];
                days = `${days}`;

                if (numbersToAddZeroTo.includes(hours) || hours < 10) {
                    hours = `0${hours}`;
                }
                if (numbersToAddZeroTo.includes(minutes) || minutes < 10) {
                    minutes = `0${minutes}`;
                }
                if (numbersToAddZeroTo.includes(seconds) || seconds < 10) {
                    seconds = `0${seconds}`;
                }
                if (days < 10) {
                    days = `0${days}`;
                }
                setTimeLeft({ days, hours, minutes, seconds });
            } else {
                setStillCountingDown(false)
            }
        }
    };
    const { days, hours, minutes, seconds } = timeLeft;

    return (
        <Wrapper>
            <CountdownRow>
                <Column>
                    <Number>{days}</Number>
                    <DateType>dagar</DateType>
                </Column>
                <Colun>:</Colun>
                <Column>
                    <Number>{hours}</Number>
                    <DateType>tim</DateType>
                </Column>
                <Colun>:</Colun>
                <Column>
                    <Number>{minutes}</Number>
                    <DateType>min</DateType>
                </Column>
                <Colun>:</Colun>
                <Column>
                    <Number>{seconds}</Number>
                    <DateType>sek</DateType>
                </Column>
            </CountdownRow>
        </Wrapper>
    )
};

export default Coundown;