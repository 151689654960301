/**
 * This is a base component for background video (Uses the base component base/video.js for the video).
 * @author Hampus Lindholm & Anton Pedersen
 * @version 1.0
 *
 * @param {node} children - JSX children
 * @param {string} className - Used by styled components (Add option to style this component)
 * @param {string|object|string[]|object[]} src - One or serveral video sources as strings or objects
 */

import React, { useEffect, useRef, useState, useMemo } from 'react';

import BaseVideo from './Video';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BackgroundVideoStyle = styled('div')`
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;
const Video = styled(BaseVideo)`
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

Video.propTypes = {
    fitVideoTo: PropTypes.string.isRequired,
};

const BackgroundVideo = ({ children, className, src, onEnded, ...rest }) => {
    // Check if your browser is IE to avoid running functions unnecessarily
    const backgroundRef = useRef(null);
    const [fitVideoTo, setFitVideoTo] = useState('width');
    const [videoRatio, setVideoRatio] = useState(null);

    // Save information from video ref
    const handleVideoRef = ref => {
        // Only run when necessary
        const videoHeight = ref && ref.videoHeight;
        const videoWidth = ref && ref.videoWidth;

        // Calc VideoRatio after video data is loaded
        setVideoRatio(videoWidth / videoHeight);


        if (ref) {
            console.log(ref, 'ref')
        }
    };

    // Run on init and if backgroundRef or videoRatio change
    useEffect(() => {
        const handleResize = () => {
            // Save information from background ref
            const backgroundRefData = backgroundRef.current ? backgroundRef.current.getBoundingClientRect() : {};
            const backgroundRatio = backgroundRefData.width / backgroundRefData.height;

            const videoOrientation = videoRatio > 1 ? 'horizontal' : 'vertical';

            // Set default to fit value
            let fitTo = fitVideoTo;

            if (videoOrientation === 'horizontal') {
                fitTo = 'height';

                // No longer fills the entire box with fitTo height change back to fitTo width
                // This can occur when the videoHeight is smaller than the backgroundHeight
                if (backgroundRatio > videoRatio) {
                    fitTo = 'width';
                }
            } else if (videoOrientation === 'vertical') {
                fitTo = 'width';

                // No longer fills the entire box with fitTo height change back to fitTo width
                // This can occur when the videoWidth is smaller than the backgroundWidth
                if (backgroundRatio < videoRatio) {
                    fitTo = 'height';
                }
            }
            setFitVideoTo(fitTo);
        };

        // Only run when necessary
        // Run handleResize on init
        handleResize();
        window.addEventListener('resize', handleResize);


        return () => window.removeEventListener('resize', handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backgroundRef, videoRatio]);

    return useMemo(() => (
        <BackgroundVideoStyle ref={backgroundRef} className={className}>
            <Video
                {...rest}
                muted={false}
                playsInline
                controls={true}
                fitVideoTo={fitVideoTo}
                src={src}
                onLoadedMetadata={e => handleVideoRef(e.target)}
                onEnded={() => onEnded()}
            />
            {children && children}
        </BackgroundVideoStyle>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [src]
    );
};

BackgroundVideo.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    className: PropTypes.string,
    src: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.exact({
            src: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
        }),
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.exact({
                    src: PropTypes.string.isRequired,
                    type: PropTypes.string.isRequired,
                }),
            ])
        ),
    ]).isRequired,
};

BackgroundVideo.defaultProps = {
    children: false,
    className: '',
};

export default BackgroundVideo;
