import "./App.css";
import Site from "./Site";
import { BrowserRouter } from "react-router-dom";

const App = () => (
    <div className="App">
        <BrowserRouter>
            <Site />
        </BrowserRouter>
    </div>
);

export default App; 