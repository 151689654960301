import React from "react";
import styled from 'styled-components';
import colors from '../config/colors';
import { above } from '../utils/mediaqueries';

const BaseLink = styled('a')`
    ${({ styledAsButton }) => styledAsButton && `
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        
        background: ${colors.orange};
        color: ${colors.white};

        font-weight: 600;
        font-size: 1.7rem;
        text-decoration: none;
        cursor: pointer;

        ${above.lgTablet} {
            width: 158px;
            font-size: 1.4rem;
        }`
    };
`;


const Link = ({ children, to, styledAsButton, internalLink, ...props }) => (
    <BaseLink
        href={to}
        target={!internalLink && "_blank"}
        rel="noopener noreferrer"
        styledAsButton={styledAsButton}
        {...props}
    >
        {children}
    </BaseLink>
)

export default Link;