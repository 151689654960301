import PropTypes from 'prop-types';
import styled from 'styled-components';

const SVG = styled('svg')``;

SVG.defaultProps = {
    xmlns: 'http://www.w3.org/2000/svg',
};

SVG.propTypes = {
    viewbox: PropTypes.string,
    xmlns: PropTypes.string.isRequired,
};

export default SVG;
