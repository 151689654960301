import posterDesktop from './poster_1-desktop.jpg';
import posterMobile from './poster_1-mobile.jpg';
import subtitles from './subtitles1.vtt';
export const door1hej =
{
    "title": "En strimma ljus – Sarah Dawn Finer",
    "doorNumber": "Lucka 1",
    "desription": "",
    "text": "Läs mer om det stöd som finns att få",
    "readMoreLink": "https://mind.se/hitta-hjalp/sjalvmordslinjen/",

    "meta": {
        "image": ""
    },
    "video": {
        "mobile": "https://player.vimeo.com/external/658737525.hd.mp4?s=66a601ca9bb3f7d5b8723f236eea52ae26203f5d&download=1",
        "desktop": "https://player.vimeo.com/external/658621527.hd.mp4?s=b987c6e4a52d0ca36859a3fff75aa09d0a4166d1&download=1",
    },
    "poster": {
        mobile: posterMobile,
        desktop: posterDesktop
    },
    "subtitles": subtitles,
    "openDate": "27"
}

export default door1hej;