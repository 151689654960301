import React from 'react';
import SVG from './base/SVG';
import Path from './base/Path';

const Mail = () => (
    <SVG width="42px" data-name="Lager 1" viewBox="0 0 200 200">
        <Path d="M199.1 93.85v12.35c-.3 2.41-.56 4.83-.9 7.23a99.12 99.12 0 0 1-80.65 83.82c-3.64.66-7.34 1-11 1.53H94.19c-.62-.11-1.24-.25-1.87-.34-4.12-.61-8.3-.95-12.37-1.83-23.64-5.12-43.05-17.22-57.72-36.42C3.88 136.17-2.3 109.05 3.86 79.46c5.4-25.9 19.48-46.35 41.29-61.19C66.76 3.56 90.76-1.51 116.54 2.61a95.26 95.26 0 0 1 49.2 23.52A97.17 97.17 0 0 1 197.59 83c.64 3.6 1.01 7.23 1.51 10.85Zm-98.84 51.05h50.32c8.33 0 13.41-5.13 13.41-13.49V68.74c0-8.51-5-13.59-13.5-13.59H50.24c-8.47 0-13.51 5.08-13.52 13.58 0 20.76.17 41.52-.08 62.28-.09 7.84 5.37 14.11 13.88 14 16.57-.29 33.16-.11 49.74-.11Z" />
        <Path d="m47.11 70.65 9.68 7.67Q76.58 94 96.34 109.73c3 2.4 5 2.41 8.08 0l46.8-37.16c.7-.56 1.4-1.1 2.4-1.88.06.89.14 1.47.14 2.06v58.44c0 3.05-.54 3.57-3.61 3.57H50.47c-2.9 0-3.51-.55-3.52-3.27v-59c.05-.49.05-1.01.16-1.84Z" />
        <Path d="M57.11 65.4h86.46c-.61.56-1 1-1.4 1.29q-20.23 16.08-40.45 32.15c-.94.76-1.56.92-2.6.09Q78.63 82.58 58.07 66.3c-.24-.19-.45-.42-.96-.9Z" />
    </SVG>
);

export default Mail;
