import React from "react";
import styled from 'styled-components';
import Facebook from '../assets/icons/Facebook';
import Mail from '../assets/icons/Mail';
import Twitter from "../assets/icons/Twitter";
import Link from './Link';
import shareImage from '../assets/images/sharer-image.jpg';

const Overlay = styled('div')`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  
    background: rgb(0 0 0 / 41%);
    z-index: 999;
    pointer-events:${({ showPopup }) => showPopup ? 'unset' : 'none'};
    visibility: ${({ showPopup }) => showPopup ? 'visible' : 'hidden'};
    opacity: ${({ showPopup }) => showPopup ? '1' : '0'};
    transition: all 400ms ease;
`;
const OverlayContent = styled('div')`
    width: 100%;
    max-width: 410px;
    height: 300px;
    background: white;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 14px 14px 30px;

    p {
        font-size: 11px;
        line-height: 15px;
        color: black;
    }
`;

const ImageWrapper = styled('div')`
    width: 100%;
    height: 130px;
`;

const ShareSection = styled('div')`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const IconWrapper = styled('div')`
    padding: 0px 6px;
`;

const SharePopup = ({ showPopup, title = "7 days of hell", onOutsideClick }) => {
    const shareText = "Just nu pågår veckan då flest kontaktar Minds Självmordslinje. Gå in på https://7daysofhell.se/ där kända röster ger liv åt berättelser baserade på historier som Mind får ta del av varje dag. Läs också om det stöd som finns att få och hur du kan bidra för att göra skillnad."
    return (
        <Overlay showPopup={showPopup} onClick={onOutsideClick}>
            <OverlayContent onClick={e => e.stopPropagation()}>
                <ImageWrapper>
                    <img src={shareImage} alt={`${title}`} width="100%" height="100%" />
                </ImageWrapper>
                <p>{shareText}</p>
                <ShareSection>
                    <IconWrapper>
                        <Link to={`https://www.facebook.com/sharer/sharer.php?u=https://7daysofhell.se&title=${title}`} onClick={`window.open(http://www.facebook.com/sharer/sharer.php?u=https://7daysofhell.se&title=${title}, newwindow, width=500, height=500`}>
                            <Facebook />
                        </Link>
                    </IconWrapper>
                    <IconWrapper>
                        <Link to={`http://twitter.com/intent/tweet?url=https://7daysofhell.se&text=${title}`} onclick={`window.open('http://twitter.com/intent/tweet?url=https://7daysofhell.se&text=${title}, 'newwindow', 'width=500, height=500');`}>
                            <Twitter />
                        </Link>
                    </IconWrapper>
                    <IconWrapper>
                        <Link to={`mailto:?subject=${title}&body=${shareText}`}>
                            <Mail />
                        </Link>
                    </IconWrapper>
                </ShareSection>
            </OverlayContent>
        </Overlay>
    );
};

export default SharePopup;