import posterDesktop from './poster_2-desktop.jpg';
import posterMobile from './poster_2-mobile.jpg';
import subtitles from './subtitles2.vtt';

export const door1hej =
{
    "title": "Nu är det jul. Igen. – Lasse, volontär på Mind",
    "doorNumber": "Lucka 2",
    "desription": "",
    "text": "Läs mer om psykisk ohälsa bland äldre vuxna och det stöd som finns att få.",
    "readMoreLink": "https://mind.se/hitta-hjalp/aldrelinjen/",

    "meta": {
        "image": ""
    },
    "video": {
        "mobile": "https://player.vimeo.com/external/658768535.hd.mp4?s=bca9b8db9d1f4e5fe142ea2ce7f048b733f1b886&download=1",
        "desktop": "https://player.vimeo.com/external/658657630.hd.mp4?s=c80256e73ab474e2f782e5748b17de1f2809f500&download=1",
    },
    
    "poster": {
        mobile: posterMobile,
        desktop: posterDesktop
    },
    "subtitles": subtitles,

    "openDate": "27"
}

export default door1hej;
