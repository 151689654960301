import React from 'react';
import SVG from './base/SVG';
import Path from './base/Path';

const Facebook = ({ margin }) => (
    <SVG margin={margin} width="42px" data-name="Lager 1" viewBox="0 0 200 200">
        <Path d="M100.33.8A99.21 99.21 0 0 0 1.12 100a99.2 99.2 0 0 0 99.21 99.2 99.2 99.2 0 0 0 99.2-99.2A99.2 99.2 0 0 0 100.33.8ZM128 61.06h-13.07c-4.61 0-5.57 1.88-5.57 6.66v11.53H128l-1.79 20.25h-16.85V160H85.21V99.75H72.65v-20.5h12.56V63.11C85.21 48 93.31 40 111.28 40H128Z" />
    </SVG>
);

export default Facebook;
