import React from 'react';
import SVG from './base/SVG';
import Path from './base/Path';

const MindSmallLogo = ({ width, className }) => {
    return (

        <SVG width={width} className={className} fill="white" space="preserve" viewBox="0 0 641.6 695.3">
            <Path d="M292.6 210.9h-.1L241 314.7l-51.6-103.8-.2.1v-.1H148v162.4h41.2v-70.8l35.4 70.8h32.8l35.2-70.7v70.7h41.3V210.9h-41.3zM469.9 404c-21.1-18.4-51.5-18.9-71.7-19.1h-52.7v162.4h65.1c17.3-.2 63.5-1.1 78.7-51.3 2.1-6.9 4.2-17.3 4.2-31.3.1-22.7-6.5-45.5-23.6-60.7zm-70 110.7h-12.6v-95.5h16.6c12.6 0 28.6-.2 39.8 14.5 7.5 10.1 8.7 21.4 8.7 32.7 0 46.6-30.5 47.8-52.5 48.3zm-107.3-25.3L189.2 384.9H148v162.4h41.2V442.9l103.4 104.4h41.3V384.9h-41.3zm53-278.5h41.2v162.4h-41.2zm20.6-12.6c14.1 0 25.6-11.3 25.6-25.2S380.3 148 366.2 148c-14.1 0-25.6 11.3-25.6 25.2s11.4 25.1 25.6 25.1z" class="st0" />
        </SVG>
    );
};

export default MindSmallLogo;
