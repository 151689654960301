import React, { Fragment } from "react";
import styled from 'styled-components';
import SevenDaysOfHellLogo from '../assets/icons/sevenDaysOfHellLogo';
import Countdown from './Countdown';
import { above, below } from '../utils/mediaqueries'
import colors from '../config/colors';

const TextSection = styled('div')`
    ${below.lgTablet} {
        position: absolute;
        left: 0;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 88px;
    width: 100%;
    padding: 16px 16px ${({ stillCountingDown }) => stillCountingDown ? '102px' : '0'};
    grid-column: 1 / span 12;
    ${below.smPhone} {
       padding: 0;
    }
    ${above.lgTablet} {
       padding: 16px 34px ${({ stillCountingDown }) => stillCountingDown ? '102px' : '0'};
    }
    h1 {
        font-size: 2.2rem;
        line-height: 21px;
        margin: 16px 0 14px;

        ${above.lgTablet} {
            font-size: 1.8rem;
            line-height: 16px;
            margin: 24px 0 12px;

        }
    }

    p {
        font-size: 1.6rem;
        line-height: 21px;
        font-weight: 500;
        margin: 0;

        ${above.lgTablet} {
            font-size: 1.4rem;
            line-height: 18px;
        }
    }

    ${above.lgTablet} {
        max-width: 400px;
        grid-column: 3 / span 3;
        padding: 0 40px 0 0;
        top: -6%;
        margin: auto;
        align-items: flex-end;
        text-align: right;
    }

    button {
        display: none;

        ${above.lgTablet} {
            display: block;
            margin-top: 32px;
            width: 157px;
            background: ${colors.orange};
            color: ${colors.white};
            border: none;
            padding: 12px 0px;
            font-weight: 600;
            font-size: 1.4rem;
            cursor: pointer;
            :hover {
                opacity: 0.8;
            }

            transition: opacity 250ms ease;

         }
    }

    .logo-site-mobile {
        
        ${below.smPhone} {
            width: 80px;
        }

    }

    .logo-site-desktop {
        display: none;
    }

    ${above.lgTablet} {
        .logo-site-desktop {
            display: block;
        }
        .logo-site-mobile {
            display: none;
        }
    }
`;

const CountdownHeading = styled('div')`
    font-size: 2rem;
    line-height: 21px;
    margin: 24px 0 12px;
    font-weight: 600;
`;

const HomePageTextSection = ({ calenderStartDate, setStillCountingDown, stillCountingDown }) => (
    <TextSection stillCountingDown={stillCountingDown}>
        <SevenDaysOfHellLogo className="logo-site-mobile" width="150px" fill="white" />
        <SevenDaysOfHellLogo className="logo-site-desktop" width="208px" fill="white" />

        <h1>Kalendern om psykisk ohälsa är fortfarande öppen</h1>
        <p>
            Vi har just lämnat perioden där fler än någonsin kontaktar Minds Självmordslinje, men luckorna är fortfarande öppna. Klicka in och hör kända röster berätta om hur en dag med psykisk ohälsa kan se ut, vilket stöd som finns att få och hur du kan bidra till att göra skillnad.
        </p>
        {stillCountingDown &&
            <Fragment>
                <CountdownHeading>Första luckan öppnas om</CountdownHeading>
                <Countdown dateToCountdown={calenderStartDate} setStillCountingDown={setStillCountingDown} />
            </Fragment>
        }
    </TextSection>
);

export default HomePageTextSection;