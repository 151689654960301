// smPhone: 20.0625em / 321px
// lgPhone: 25.9375em / 415px
// smTablet: 48em / 768px
// lgTablet: 64.0625em / 1025px
// smDesktop: 75.0625em / 1201px
// lgDesktop: 90.0625em / 1441px
// xlDesktop: 125.0625em / 2000px

export enum BreakPoints {
  "smPhone" = "20.0625em",
  "lgPhone" = "25.9375em",
  "smTablet" = "48em",
  "lgTablet" = "64.0625em",
  "smDesktop" = "75.0625em",
  "lgDesktop" = "90.0625em",
  "xlDesktop" = "125.0625em"
}

export type BreakPointsType = {
  smPhone: string;
  lgPhone: string;
  smTablet: string;
  lgTablet: string;
  smDesktop: string;
  lgDesktop: string;
  xlDesktop: string;
};

export const breakpoints = [
  "20.0625em",
  "25.9375em",
  "48em",
  "64.0625em",
  "75.0625em",
  "90.0625em",
  "125.0625em"
];
export const labels = [
  "smPhone",
  "lgPhone",
  "smTablet",
  "lgTablet",
  "smDesktop",
  "lgDesktop",
  "xlDesktop"
];
