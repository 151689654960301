

import React from 'react';
import SVG from './base/SVG';

const Close = ({ width, height, className }) => (
    <SVG
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        width={width} height={height}>
        <image x="0px" y="0px" width={width} height={height} href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGwAAABsCAQAAAAlb59GAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQflDBAXLjLFDXdWAAACJElEQVR42t3cS3KDMBAEUMoX4GZcmpWv5E1ngyuE8NFnPq1mT02/lIOxNJppurwwY8UykV5YsGJuuXHGG8CHk4YFHwDvatrGAidtY6GatmMR0nasOtqBRUY7sMppJywi2gmrjHbBIqFdsJ5pNywC2g3rnvbASqY9sK5pBaxEWgHrnFbISqIVsv7TKlgJtArWX1olK5hWyfqlNbACaQ2sLw1rw41BtEYWAKw9NzvTupNx0kxS8dHMEnHRTNPw0MyTcNBcUuTT3BLk0lyr59HcK+fQQqrG08IqxtJCq8UVC/98xBRM+Y/2L5r2DPYtnPqt6Vc8/T3HJ0A6yycEBcs+CA3LNgwVyy4QHcsmFCWrPxgtq5vGy+qkMbPCabF7O2G0+N24EFrO/qk7LW/H25WW26PgRsvvKnGh5bNcaBwscxoPy5TGxTKj8bFMaJysbpox65X9xxjgEv0oij48RB/3ol/Qoq9Uoi/Boj9bRH9oii4NiC7miC6/iS6Yii5xi25KiG4jiW78iW7Vim6ui7ZDiDawiLYciTaJibb1iTZiirbOijY7i7anix4oED0CInpoR/SYVfprjup5P5cUHCzzJDws0zRcLLNEfCyTVJwsA5ru2AvVQSWyo2Wmetoow4AqaSONb6qgjTZwq5A24oi0AtqoQ+0eaPlNJe1jCG9oBKwbWvNMTBLWBa15iikR64TWPHeWjHWgNU8KJmTtaPWsjTbwyOof1rFy5+cJw8YAAAAASUVORK5CYII=" />
    </SVG>
);

export default Close;
