import posterDesktop from './poster_7-desktop.jpg';
import posterMobile from './poster_7-mobile.jpg';
import subtitles from './subtitles7.vtt';

export const door1hej =
{
    "title": "Ensam bland vänner – Henrik Wahlström",
    "doorNumber": "Lucka 7",
    "desription": "",
    "text": "Läs mer om olika sätt att uppleva ensamhet",
    "readMoreLink": "https://mind.se/ensamhet/",

    "meta": {
        "image": ""
    },
    "video": {
        "mobile": "https://player.vimeo.com/external/658796025.hd.mp4?s=3fab490be08cac71c5bf925fe42b9744db8ffb1e&download=1",
        "desktop": "https://player.vimeo.com/external/657088128.hd.mp4?s=b2d1e531c992e99c4aff17b127f87dd30e309e7b&download=1",
    },
    "poster": {
        mobile: posterMobile,
        desktop: posterDesktop
    },
    "subtitles": subtitles,
    "openDate": "27"
}

export default door1hej;